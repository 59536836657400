@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@include mat.core();
/**
primary - used to represent primary interface elements for a user. It's the color displayed most frequently across your app's screens and components.
secondary - used to represent secondary interface elements for a user. It provides more ways to accent and distinguish your product. Having it is optional.
error - used to represent interface elements that the user should be made aware of.
warning - used to represent potentially dangerous actions or important messages.
info - used to present information to the user that is neutral and not necessarily important.
success - used to indicate the successful completion of an action that user triggered.
**/

$my-primary: mat.define-palette(mat.$deep-purple-palette, A700);
$my-accent: mat.define-palette(mat.$teal-palette, A400);
$my-info: mat.define-palette(mat.$amber-palette, 800);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   info: $my-info
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

@include mat.all-component-themes($my-theme);